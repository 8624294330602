import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import ImageViewer from './components/ImageViewer';
import HistoryTable from './components/HistoryTable';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
    console.log('App.js - isAuthenticated:', isAuthenticated);
    console.log('App.js - setIsAuthenticated is a function:', typeof setIsAuthenticated === 'function');
  }, [isAuthenticated]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route 
          path="/view/:timestamp" 
          element={isAuthenticated ? <ImageViewer /> : <Navigate to="/" />} 
        />
        <Route 
          path="/history" 
          element={isAuthenticated ? <HistoryTable /> : <Navigate to="/" />} 
        />
      </Routes>
    </Router>
  );
}

export default App;
