import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config';
import './ImageViewer.css';
import homeIcon from '../assets/home-icon.png';
import reloadIcon from '../assets/reload-icon.png';
import clockIcon from '../assets/clock-icon.png';
const isoTable = require('../isotable.json');

function ImageViewer() {
  const { timestamp } = useParams();
  const [details, setDetails] = useState(null);
  const [isTopFlipped, setIsTopFlipped] = useState(false);
  const [isTopFlippedPOP, setIsTopFlippedPOP] = useState(false);
  const [isLeftActive, setIsLeftActive] = useState(false);
  const [isLeftFlippedPOP, setIsLeftFlippedPOP] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [damageDetails, setDamageDetails] = useState({});
  const navigate = useNavigate();

  
  useEffect(() => {
    document.title = 'ImageViewer - CVHistory';
    const fetchLatestTimestamp = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/history/latest-timestamp`);
        return response.data.timestamp;
      } catch (error) {
        console.error('Failed to fetch latest timestamp', error);
        return null;
      }
    };


    const fetchDetails = async (timestampToFetch) => {
      try {
        console.log(`Fetching details for timestamp: ${timestampToFetch}`);
        const response = await axios.get(`${config.API_BASE_URL}/history/folder-details/${timestampToFetch}`);
        console.log('Details fetched:', response.data);
        setDetails(response.data);

        const folderPath = `${config.API_BASE_URL}/history/${timestampToFetch}`;
        try {
          const damageResponse = await axios.get(`${folderPath}/Damage.json`);
          if (damageResponse.headers['content-type'].includes('application/json')) {
            setDamageDetails(damageResponse.data);
          } else {
            console.error('Unexpected response format for Damage.json:', damageResponse.data);
            setDamageDetails({
              anomaly_prob: 'No Data',
              loc_code: 'No Data',
              distances: 'No Data',
              length: 'No Data',
              failed_pcd: 'No Data',
            }); // ダミーデータ
          }
        } catch (error) {
          console.error('Failed to fetch Damage.json', error);
          setDamageDetails({
            anomaly_prob: 'No Data',
            loc_code: 'No Data',
            distances: 'No Data',
            length: 'No Data',
            failed_pcd: 'No Data',
          }); // ダミーデータ
        }
      } catch (error) {
        console.error('Failed to fetch folder details', error);
      }
    };

    if (timestamp === 'latest') {
      fetchLatestTimestamp().then(latestTimestamp => {
        if (latestTimestamp) {
          navigate(`/view/${latestTimestamp}`);
          fetchDetails(latestTimestamp);
        }
      });
    } else {
      fetchDetails(timestamp);
    }
  }, [timestamp, navigate]);

  const toggleTopFlip = async () => {
    setIsTopFlipped(prevState => !prevState);
  };
  const toggleLeftActive = () => {
    setIsLeftActive((prevState) => !prevState);
  };
  const handleLatest = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/history/latest-timestamp`);
      const latestTimestamp = response.data.timestamp;
      navigate(`/view/${latestTimestamp}`);
    } catch (error) {
      console.error('Failed to fetch latest timestamp', error);
    }
  };

  if (!details) {
    return <div>Loading...</div>;
  }

  const navigateToHistory = () => {
    navigate('/history');
  };

  const navigateNextContainer = async () =>{
    try {
      const response = await axios.get(`${config.API_BASE_URL}/history/next-timestamp/${timestamp}`);
      const cTimestamp = response.data.timestamp;
      navigate(`/view/${cTimestamp}`);
    }
    catch (error){
      console.error('Failed to fetch next timestamp', error);
    }
  }
  const navigatePrevContainer = async () =>{
    try {
      const response = await axios.get(`${config.API_BASE_URL}/history/prev-timestamp/${timestamp}`);
      const cTimestamp = response.data.timestamp;
      navigate(`/view/${cTimestamp}`);
    }
    catch(error){
      console.error('Failed to fetch prev timestamp', error);
    }
  }

  const formattedDate = `${timestamp.slice(0, 4)}/${timestamp.slice(4, 6)}/${timestamp.slice(6, 8)} (${['日', '月', '火', '水', '木', '金', '土'][new Date(timestamp.slice(0, 4), timestamp.slice(4, 6) - 1, timestamp.slice(6, 8)).getDay()]}) ${timestamp.slice(8, 10)}:${timestamp.slice(10, 12)}`;
  const size = details.size;
  const lengthCode = isoTable.Length[size.charAt(0)] || '--';
  const containerCode = isoTable.Container[size.slice(-2)] || '--';
  var heightCode = isoTable.Height[size.charAt(1)] || '--';
  if(heightCode !== '--'){
    heightCode = heightCode + '"'
  }
  const containerNumber = details.container ?
  `${details.container.slice(0, 4)} ${details.container.slice(4)} (${size} / ${lengthCode} ${containerCode} ${heightCode})` 
  : 'コンテナ番号不明';
  var showLocCode = damageDetails.loc_code===';;' ? 'No Damage' : damageDetails.loc_code;

  const handleThumbnailClick = (image, index) => {
    if (index === 0) {
      setIsLeftFlippedPOP(isLeftActive);
      setIsTopFlippedPOP(false);
      setSelectedImage(image);
    }
    if (index === 4) {
      setIsLeftFlippedPOP(false);
      setIsTopFlippedPOP(isTopFlipped);
      setSelectedImage(image);
    }
    if (index === 5) {
      setIsLeftFlippedPOP(false);
      setIsTopFlippedPOP(false);
      setSelectedImage(image);
    }
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-viewer-container">
      <div className="image-viewer-header">
        <div className="title-container">
          <img src={homeIcon} alt="Home" />
          <h1>ダメージ検出試験</h1>
        </div>
        <button onClick={handleLatest}>
          <img src={reloadIcon} alt="Reload" />
          最新
        </button>
      </div>
      <div className="image-viewer-date-time">
        <img src={clockIcon} alt="Clock" />
        <p>{formattedDate}</p>
        <button onClick={navigateToHistory}>履歴</button>
        <button onClick={navigatePrevContainer}>前コンテナ</button>
        <button onClick={navigateNextContainer}>次コンテナ</button>
      </div>
      <div className="image-viewer-container-number">
        {containerNumber}
      </div>
      <div className="image-viewer-damage">
        <div className="image-viewer-damage-label">ダメージ</div>
        <div className="image-viewer-damage-value">{showLocCode}</div>
      </div>
      <div className="CDirectionCtrl">
       <button className="ToggleBtnDirection" onClick={toggleLeftActive}>進行方向切替</button>
       <div className="arrow-container">
        {isLeftActive ? (
            <span className="arrow">F ← R</span>
          ) : (
            <span className="arrow">R → F</span>
          )
        }
       </div>
      </div>
      <div className="image-viewer-thumbnails">
        {details.images.map((image, index) => (
          <img
           key={index} 
           src={`${config.API_BASE_URL}/history/${timestamp}/${image}`} 
           alt={image} 
           onClick={() => handleThumbnailClick(image, index)}
           className={isTopFlipped&&index===4?"flipped-image"
            : (isLeftActive && index >= 0 && index <= 3) ? "flipped-image-left" : ""}
          />
        ))}
      </div>
      <div className="topflip-ctrl">
        <button onClick={toggleTopFlip} className="toggle-flip-button">
          {isTopFlipped ? "Top画像上下反転を解除" : "Top画像上下反転"}
        </button>
      </div>
      <div className="image-viewer-damage-details">
        <p>AI結果</p>
        <p>　Anomaly Prob: {damageDetails.anomaly_prob}</p>
        <p>　Loc Code: {damageDetails.loc_code}</p>
        <p>　Distances: {damageDetails.distances}</p>
        <p>　Length: {damageDetails.length}</p>
        <p>　Failed PCD: {damageDetails.failed_pcd}</p>
      </div>
      {selectedImage && (
        <div className="image-viewer-popup">
          <img
           src={`${config.API_BASE_URL}/history/${timestamp}/${selectedImage}`}
           alt={selectedImage}
           className={isTopFlippedPOP ? "flipped-image" 
            : isLeftFlippedPOP ? "flipped-image-left" : ""}
          />
          <div className="close-button" onClick={handleClosePopup}>&times;</div>
          <div className="container-number">{containerNumber}</div>
        </div>
      )}
    </div>
  );
}

export default ImageViewer;
